import { atom } from "recoil";

export const uiInitialLogoDarkForced = atom<boolean>({
  key: "ui/logo-initial-dark",
  default: true,
});

export const uiBottomDrawerOpenedAtom = atom<boolean>({
  key: "ui/mobile-bottom-drawer-open",
  default: false,
});
